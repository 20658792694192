/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const validateFastaUpload = /* GraphQL */ `
  mutation ValidateFastaUpload($input: ValidateFastaInput) {
    validateFastaUpload(input: $input)
  }
`;
export const startAnalysis = /* GraphQL */ `
  mutation StartAnalysis($input: RunAnalysisInput!) {
    startAnalysis(input: $input) {
      status
      experimentName
      message
    }
  }
`;
export const startFlashAnalysis = /* GraphQL */ `
  mutation StartFlashAnalysis($input: FlashAnalysisInput!) {
    startFlashAnalysis(input: $input) {
      status
      experimentName
      message
    }
  }
`;
export const analysisStepComplete = /* GraphQL */ `
  mutation AnalysisStepComplete(
    $status: String!
    $experimentName: String!
    $message: String
  ) {
    analysisStepComplete(
      status: $status
      experimentName: $experimentName
      message: $message
    ) {
      status
      experimentName
      message
    }
  }
`;
export const publishExperiment = /* GraphQL */ `
  mutation PublishExperiment($input: PublishExperimentInput!) {
    publishExperiment(input: $input) {
      status
      experimentName
      message
    }
  }
`;
export const publishComplete = /* GraphQL */ `
  mutation PublishComplete($input: PublishCompleteInput) {
    publishComplete(input: $input) {
      status
      experimentName
      message
    }
  }
`;
export const deleteExperiment = /* GraphQL */ `
  mutation DeleteExperiment(
    $experimentName: String!
    $experimentType: String!
  ) {
    deleteExperiment(
      experimentName: $experimentName
      experimentType: $experimentType
    )
  }
`;
export const revertExperiment = /* GraphQL */ `
  mutation RevertExperiment(
    $experimentName: String!
    $experimentType: String!
  ) {
    revertExperiment(
      experimentName: $experimentName
      experimentType: $experimentType
    )
  }
`;
export const labelCheckPcaAnalysis = /* GraphQL */ `
  mutation LabelCheckPcaAnalysis($input: LabelEfficiencyPcaInput) {
    labelCheckPcaAnalysis(input: $input) {
      experimentName
      plot {
        id
        data {
          x
          y
        }
      }
      message
    }
  }
`;
export const uploadPlannedExperiment = /* GraphQL */ `
  mutation UploadPlannedExperiment($input: [ExperimentTableInput]!) {
    uploadPlannedExperiment(input: $input) {
      experiment
      record_type
      schema_version
      record_value {
        type
        projectName
        conditions {
          channel
          cells
          genotype
          treatmentTime
          treatmentTimeUnit
          type
          taxon
          description
          compounds {
            batch
            compound
            concentration {
              concentration
              unit
            }
          }
        }
        responsible
        collaborator
        benchling
        noOfSamples
      }
      edit_date
      creation_date
    }
  }
`;
export const getNextExperimentNumber = /* GraphQL */ `
  mutation GetNextExperimentNumber($year: Int!) {
    getNextExperimentNumber(year: $year) {
      year
      experiment_counter
    }
  }
`;
export const updateLcmsInfo = /* GraphQL */ `
  mutation UpdateLcmsInfo(
    $experiment: String!
    $start: String!
    $end: String!
    $instrument: String!
  ) {
    updateLcmsInfo(
      experiment: $experiment
      start: $start
      end: $end
      instrument: $instrument
    ) {
      experiment
      exp_type
      exp_status
      planned_date
      processed_date
      published_date
      responsible
      collaborator
      projectName
      benchling
      lcms_start
      lcms_end
      instrument
      experiment_folder
    }
  }
`;
export const completeExperiment = /* GraphQL */ `
  mutation CompleteExperiment(
    $experimentName: String!
    $experimentType: String!
    $status: String!
  ) {
    completeExperiment(
      experimentName: $experimentName
      experimentType: $experimentType
      status: $status
    )
  }
`;
export const createConvertedExperimentDownload = /* GraphQL */ `
  mutation CreateConvertedExperimentDownload(
    $experiment: String!
    $fileType: PXPfileType!
    $method: PXPAnalysisMethod!
  ) {
    createConvertedExperimentDownload(
      experiment: $experiment
      fileType: $fileType
      method: $method
    )
  }
`;
export const getConvertedExperimentDownloadUrl = /* GraphQL */ `
  mutation GetConvertedExperimentDownloadUrl($input: GeneratedFileDownload) {
    getConvertedExperimentDownloadUrl(input: $input) {
      experiment
      fileType
      method
      url
      key
    }
  }
`;
export const updateBarcodePair = /* GraphQL */ `
  mutation UpdateBarcodePair($parent: String!, $child: String!) {
    updateBarcodePair(parent: $parent, child: $child) {
      keys
      cancellationReasons
    }
  }
`;
export const deleteBarcodePair = /* GraphQL */ `
  mutation DeleteBarcodePair($parent: String!, $child: String!) {
    deleteBarcodePair(parent: $parent, child: $child) {
      keys
      cancellationReasons
    }
  }
`;
export const processFlashSampleList = /* GraphQL */ `
  mutation ProcessFlashSampleList($input: FlashSampleListInput) {
    processFlashSampleList(input: $input) {
      status
      experimentName
      message
    }
  }
`;
export const acknowledgeFlashWarnings = /* GraphQL */ `
  mutation AcknowledgeFlashWarnings($experimentName: String!, $user: String!) {
    acknowledgeFlashWarnings(experimentName: $experimentName, user: $user) {
      experiment
      record_type
      record_value {
        conditions {
          channel
          cells
          treatmentTime
          description
          ... on Condition {
            genotype
            treatmentTimeUnit
            type
            taxon
            compounds {
              batch
              compound
              concentration {
                concentration
                unit
              }
            }
          }
          ... on TMTCondition {
            compound
            cddBatchId
            concentration {
              concentration
              unit
            }
          }
        }
        type
        tmt_lot
        projectName
        responsible
        collaborator
        benchling
        noOfSamples
        schema_version
        experiment_folder
        scinamicPlateMap
        cellCultureBarcode
        peptideBarcode
        flashWarningsAcknowledged
      }
    }
  }
`;
export const updateFlashSampleList = /* GraphQL */ `
  mutation UpdateFlashSampleList($experimentName: String!) {
    updateFlashSampleList(experimentName: $experimentName)
  }
`;
