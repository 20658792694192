import {useContext} from 'react';
import {
    Typography, Table, TableContainer, TableHead, TableBody, TableRow
} from '@mui/material';
import {
    TMTOptions, TMT_TYPES, emptyCellLine
} from '../../../util/options';
import { StyledTheadCell } from './StyledComponents';
import { DesignTableDataCy } from './DesignTable.cy';
import DesignTableRow from './DesignTableRow';
import {ReferenceContext} from '../../../store/ReferenceContext';

type DesignTableProps = {
    rowData: any;
    newExperimentType: string;
    useCompound: boolean;
    handleDuplicate: (index: number) => void;
    handleClearRow: (index: number) => void;
    handleRowUpdate: (index: number, row: SampleRow) => void
}

export default function DesignTable({
    rowData,
    newExperimentType,
    useCompound,
    handleDuplicate,
    handleClearRow,
    handleRowUpdate
}: DesignTableProps) {

    const referenceContext = useContext<References>(ReferenceContext)
    const lines = [...referenceContext.cellLines];
    lines.unshift(emptyCellLine)

    return (
        <TableContainer id="table-container"
            sx={(theme) => ({ mt: '-2px', maxHeight: '63vh', border: `1px solid ${theme.palette.divider}`})}
        >
            <Table stickyHeader data-cy={DesignTableDataCy.container}>
                <colgroup>
                    <col span={1} style={{width: 50}}/>
                    <col span={1} style={{minWidth: 70}}/>
                    <col span={1} style={{minWidth: 300}}/>
                    <col span={1} style={{minWidth: 100}}/>
                    <col span={1} style={{minWidth: 50}}/>
                    <col span={1} style={{minWidth: 100}}/>
                    <col span={1} style={{minWidth: 60}}/>
                    <col span={1} style={{minWidth: 50}}/>
                    <col span={1} style={{minWidth: 100}}/>
                    <col span={1} style={{width:'inherit'}}/>
                </colgroup>
                <TableHead>
                    <TableRow>
                        <StyledTheadCell align="right" >Sample</StyledTheadCell>
                        {TMT_TYPES.includes(newExperimentType) && (
                        <StyledTheadCell>TMT</StyledTheadCell>
                        )}
                        <StyledTheadCell>
                            Cells
                            <Typography variant="caption" display="block">Required</Typography>
                        </StyledTheadCell>
                        <StyledTheadCell>
                            Compound
                            <Typography variant="caption" display="block">Required</Typography>
                        </StyledTheadCell>
                        <StyledTheadCell>
                            Batch
                            <Typography variant="caption" display="block">Required</Typography>
                        </StyledTheadCell>
                        <StyledTheadCell>
                            Concentration
                            <Typography variant="caption" display="block">Required</Typography>
                        </StyledTheadCell>
                        <StyledTheadCell>
                            Treatment Time
                            <Typography variant="caption" display="block">Required</Typography>
                        </StyledTheadCell>
                        <StyledTheadCell>
                            Type
                            <Typography variant="caption" display="block">Required</Typography>
                        </StyledTheadCell>
                        <StyledTheadCell>Description</StyledTheadCell>
                        <StyledTheadCell>
                            <Typography variant="srOnly">Sample Row Options</Typography>
                            &nbsp;
                        </StyledTheadCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rowData.map((row: any, index: number) => (
                        <DesignTableRow key={`trow-${index}`} row={row} index={index} isTmt={TMT_TYPES.includes(newExperimentType)}
                                               useCompound={useCompound}
                                               cellLines={lines}
                                               availableTmtValues={TMTOptions}
                                               tmtInUse={rowData.map((r: SampleRow) => r.tmt)}
                                               newExperimentType={newExperimentType}
                                               handleDuplicate={handleDuplicate}
                                               handleClearRow={handleClearRow}
                                               handleRowUpdate={handleRowUpdate}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}