import React, {useState} from 'react';
import {
    Box, Grid, FormControl, FormHelperText,
    InputLabel, TextField, NativeSelect, OutlinedInput, Autocomplete
} from '@mui/material';
import {experimentTypes} from '../../../util/options';
import { ExperimentFormDataCy } from './ExperimentForm.cy';
import AlertDialog from '../AlertDialog/AlertDialog';
import CommonElements from './CommonElements';
const AlertTitle = {
    warning: 'Warning',
    error: 'Error'
};

export const AlertErrorMessages = {
    filledDataCanBeLost: 'Filled data will be lost. Do you want to change the number of sample?'
};

export const AlertButtons = {
    yes: 'Yes',
    cancel: 'Cancel',
};

const sampleOptions = [4,5,6,7,8,9,10,11,12,13,14,15,16,17,18];

type ExperimentFormProps = {
    experimentType: string;
    setExperimentType: (type: string) => void;
    numSamples: number;
    numSamplesChangeHandler: (numSamples: number) => void;
    projectName: string;
    responsible: string;
    collaborator: string;
    handleSummaryChange: (key: string, value: any) => void;
    tmt: boolean
    // NEXT two only applicable when tmt is FALSE
    benchling?: string;
    experiment_folder?: string;
}

export default function ExperimentForm({
    experimentType,
    setExperimentType,
    numSamples,
    numSamplesChangeHandler,
    projectName,
    responsible,
    collaborator,
    handleSummaryChange,
    tmt,
    benchling,
    experiment_folder,
}: ExperimentFormProps){

    const [tempNumSamples, setTempNumSamples] = useState<number>(16);
    const [openSampleWarning, setSampleWarning] = useState(false);

    const handleSampleWarningOpen = () => {
        setSampleWarning(true);
    }
    
    const handleSampleWarningClose = () => {
        setSampleWarning(false);
    }

    const handleNumSamplesChange = (e: React.ChangeEvent<{ value: string }>) => {
        const samples = parseInt(e.target.value);
        if (numSamples > samples) {
            handleSampleWarningOpen();
            setTempNumSamples(samples)
        } else {
            numSamplesChangeHandler(samples);
        }
    };

    const handleNumSampleChangeConfirmed = () => {
        numSamplesChangeHandler(tempNumSamples);
        handleSampleWarningClose();
    }

    const renderSampleNumWarnDialog = () => {
        return (
            <AlertDialog
                openDialog={openSampleWarning}
                handleDialogClose={handleSampleWarningClose}
                dialogTitle={AlertTitle.warning}
                handlePositiveClick={handleNumSampleChangeConfirmed}
                dialogContentText={AlertErrorMessages.filledDataCanBeLost}
                positiveButtonText={AlertButtons.yes}
                negativeButtonText={AlertButtons.cancel}
            />
        )
    }
    
    return (<>
        <Box pb={1.5} position="relative" zIndex={3} bgcolor="background.paper" 
            borderBottom={tmt ? 1 : 0} borderColor="divider"
        >
            <Grid container rowSpacing={2}>
                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={4} lg={3}>
                        <FormControl variant="outlined" size="small" required fullWidth>
                        {experimentType === 'QC' || experimentType === 'GENERAL' ?
                            <TextField
                                id="select-exp-type"
                                label="Experiment Type"
                                data-cy={ExperimentFormDataCy.experimentType}
                                inputProps={{ readOnly: true, }}
                                value={experimentType === 'QC' ? 'Yeast TKO' : 'General'}
                                variant="outlined" size="small" fullWidth
                                name="type"/>
                            : <Autocomplete
                                id="select-exp-type"
                                data-cy={ExperimentFormDataCy.experimentType}
                                value={experimentType? experimentTypes.find(type => type.value === experimentType): null}
                                options={experimentTypes}
                                getOptionDisabled={(option => option.disable)}
                                getOptionLabel={(option) => option.label? option.label: ""}
                                isOptionEqualToValue={(option, value) => {
                                    return option.value === value?.value;
                                }}
                                renderInput={(params) => 
                                    <TextField {...params}
                                        label="Experiment Type"
                                        placeholder="Select"
                                        helperText="Required"
                                        variant="outlined" size="small" fullWidth
                                        name="type"
                                        />
                                }
                                onChange={(event, value) => {		
                                    setExperimentType(value ? value!.value : '');
                                }}
                                forcePopupIcon openOnFocus selectOnFocus handleHomeEndKeys />
                        }
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} lg={1}>
                        {experimentType === 'QC' ?
                            <FormControl variant="outlined" size="small" required fullWidth>
                            <TextField
                                label="No. of Samples"
                                id="select-number-samples"
                                data-cy={ExperimentFormDataCy.numberOfSamples}
                                inputProps={{ readOnly: true, }}
                                value={'11'}
                                variant="outlined" size="small" fullWidth
                                name="numSamples"/>
                                </FormControl>
                            : experimentType === 'GENERAL' ?
                            <FormControl variant="outlined" size="small" required fullWidth>
                            <TextField
                                label="No. of Samples"
                                id="select-number-samples"
                                inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                                value={numSamples}
                                variant="outlined" size="small" fullWidth
                                name="numSamples"
                                onChange={(event) => {
                                    handleSummaryChange("numSamples", event.target.value);
                                }}/>
                            </FormControl>
                            : <FormControl variant="outlined" size="small" required fullWidth>
                                <InputLabel htmlFor="select-number-samples">No. of Samples</InputLabel>
                                <NativeSelect
                                required
                                data-cy={ExperimentFormDataCy.numberOfSamples}
                                value={numSamples}
                                onChange={handleNumSamplesChange}
                                inputProps={{name: 'numSamples', id: 'select-number-samples'}}
                                input={<OutlinedInput label="No. of Samples"/>}>
                                {sampleOptions.map((optionValue) => (
                                    <option key={optionValue + '_samples'} value={optionValue}>
                                        {optionValue}
                                    </option>
                                ))}
                            </NativeSelect>
                            <FormHelperText>Required</FormHelperText>
                            </FormControl>
                        }
                    </Grid>
                    {!tmt && <Grid item xs={12} lg={4}>
                        <TextField
                            id="input-benchling"
                            name={"benchling"}
                            data-cy={ExperimentFormDataCy.benchling}
                            label="Benchling"
                            helperText="Optional"
                            variant="outlined" size="small" fullWidth
                            value={benchling ? benchling: ''}
                            onChange={(event) => {
                                handleSummaryChange("benchling", event.target.value);
                            }}/>
                    </Grid>}
                </Grid>
                <CommonElements projectName={projectName} responsible={responsible}
                                collaborator={collaborator} handleSummaryChange={handleSummaryChange}
                                isQC={experimentType === 'QC'}
                                commonRequired={{projectName: true, responsible: true, collaborator: true}}/>
                {!tmt && <Grid item xs>
                    <Grid item xs={12} lg={4}>
                    <TextField
                        id="input-benchling"
                        name={"experiment_folder"}
                        label="Experiment Folder"
                        helperText="Optional"
                        variant="outlined" size="small" fullWidth
                        value={experiment_folder ? experiment_folder: ''}
                        onChange={(event) => {
                            handleSummaryChange("experiment_folder", event.target.value);
                        }}/>
                    </Grid>
                </Grid>}
            </Grid>
        </Box>
        {renderSampleNumWarnDialog()}
    </>)
}