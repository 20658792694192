import React, {FC, useEffect, useState} from 'react'
import {Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AlertDialog from '../AlertDialog';
import {AlertButtons, AlertErrorMessages, AlertTitle} from './CreateNewExperimentModal';
import FlashExperimentForm from '../ExperimentForm/FlashExperimentForm';
import CreateNewExperimentFooter from './CreateNewExperimentFooter';
import designs from '../../../services/designs';
import {experimentTypes} from '../../../util/options';
import {Typography} from '@mui/material/';
import flashService from '../../../services/flashService';

interface EditFlashProps {
    handleClose: () => void;
    experimentDesign: any;
    existingExperimentName: string;
    handleSuccess: (message: string, designType: ExperimentType) => void;
}
const CreateNewFlashExperimentModal: FC<EditFlashProps> = ({handleClose, handleSuccess,
                                    existingExperimentName, experimentDesign}) => {
    const [openCancelWarning, setOpenCancelWarning] = useState(false);
    const windowTitleId = 'NewFlashExp';
    const [projectName, setProjectName] = useState<string>(experimentDesign ? experimentDesign.projectName : '')
    const [responsible, setResponsible] = useState<string>(experimentDesign ? experimentDesign.responsible : '')
    const [collaborator, setCollaborator] = useState<string>(experimentDesign ? experimentDesign.collaborator: '')
    const [benchling, setBenchling] = useState<string>(experimentDesign ? experimentDesign.benchling : '')
    const [noOfSamples, setNoOfSamples] = useState<number>(experimentDesign ? experimentDesign.noOfSamples : 12)
    const [experiment_folder, setExperimentFolder] = useState<string>(experimentDesign ? experimentDesign.experiment_folder : '')
    const [peptideBarcode, setPeptideBarcode] = useState<string>(experimentDesign ? experimentDesign.peptideBarcode : '')
    const [tecanFile, setTecanFile] = useState<File|undefined>()
    const [errorMessages, setErrorMessages] = useState<string[]>();
    const [openFormError, setOpenFormError] = useState(false);
    const [submitInProgress, setSubmitInprogress] = useState(false)
    const [tcanExists, setTcanExists] = useState<boolean>(false)

    useEffect(() => {
        const checkTecan = async (experimentName: string) => {
            const exists: boolean = await flashService.hasTcanFile(`flash/deg/w-assay/fluorescence/${experimentName}.csv`)
            setTcanExists(exists)
        }
        checkTecan(existingExperimentName)
    }, [existingExperimentName])

    const handleCancelWarningOpen = () => {
        setOpenCancelWarning(true);
    }
    const handleCancelWarningClose = () => {
        setOpenCancelWarning(false);
    }

    /**
     * We want to avoid closing the dialog while uploading files by accidentally
     * clicking on the backdrop, thereby leaving the save in some weird half complete state.
     * @param event
     * @param reason
     */
    const doClose = (event: object, reason: string) => {
        //console.log(`start doClose with reason ${reason}`)
        if (reason !== 'backdropClick') {
            handleClose()
        }
    }

    const isValid = (existing: string | undefined,
                     data: any,
                     existingFile: boolean,
                     file: File|undefined): boolean => {
        let errors = []
        if (!data.peptideBarcode) {
            errors.push('Peptide barcode is required')
        }
        if (!existingFile && !file) {
            errors.push('Fluorescence measurement file required')
        }
        if (!data.noOfSamples) {
            errors.push("Number of samples per block is required")
        }
        if (errors.length > 0) {
            setErrorMessages(errors)
            return false
        }
        return true
    }

    const doSave = async (existingExperimentName: string, data: any, tecanFile: File) => {
        // console.log('start doSave')
        let experimentName = existingExperimentName;
        try {
            if (tecanFile) {
                const tecanUrl = await designs.getFlashUploadUrl(`flash/deg/w-assay/fluorescence/${experimentName}.csv`)
                await designs.doFlashUpload(tecanUrl, tecanFile)
            }

            // console.log('Uploads complete')
            let recordValue = {...data}
            let design: any = {
                experiment: experimentName,
                record_type: "DESIGN",
                record_value: recordValue
            }
            let status: any = {
                experiment: experimentName,
                record_type: 'FLASH_DEGRADATION_STATUS'
            }
            const experimentList = [design, status]
            const results: any = await designs.uploadPlanned(experimentList)
            if (results) {
                handleClose()
                const action = existingExperimentName ? 'updated' : 'created'
                handleSuccess(`Experiment with name '${results.data.uploadPlannedExperiment[0]?.experiment}' has been successfully ${action}!`,
                    experimentTypes.find((type) => type.value === 'FLASH_DEGRADATION')!)
            }
        } catch (error) {
            console.error('Create Flash Experiment Error', error)
        } finally {
            setSubmitInprogress(false)
        }
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setSubmitInprogress(true)
        const formData = new FormData(event.currentTarget)
        const data = Object.fromEntries(formData.entries())
        // console.log('The form data', data)
        // console.log('TECAN', tecanFile)
        // console.log('ECHO', echoFile)
        if (isValid(existingExperimentName, data, tcanExists, tecanFile)) {
            doSave(existingExperimentName, data, tecanFile!)
        } else {
            setOpenFormError(true)
            setSubmitInprogress(false)
        }
    }

    const handleSummaryChange = (key: string, value: any) => {
        switch(key) {
            case 'benchling':
                setBenchling(value)
                break
            case 'projectName':
                setProjectName(value)
                break
            case 'responsible':
                setResponsible(value)
                break
            case 'collaborator':
                setCollaborator(value)
                break
            case 'experiment_folder':
                setExperimentFolder(value)
                break
            case 'peptideBarcode':
                setPeptideBarcode(value)
                break
            case 'noOfSamples':
                setNoOfSamples(value)
                break
        }
    };

    const renderFormErrorsDialog = () => {
        return (
            <AlertDialog
                openDialog={openFormError}
                handleDialogClose={() => setOpenFormError(false)}
                dialogTitle={AlertTitle.error}
                handlePositiveClick={() => setOpenFormError(false)}
                dialogContentText={AlertErrorMessages.rectifyErrorNeeded}
                dialogContentExtraText={errorMessages?.map((error, index) => {
                    return (
                        <div key={index}>{error}</div>
                    )
                })}
                positiveButtonText={AlertButtons.ok}
            />
        )
    }

    const renderCancelExperimentDialog = () => {
        return (
            <AlertDialog
                openDialog={openCancelWarning}
                handleDialogClose={handleCancelWarningClose}
                dialogTitle={AlertTitle.warning}
                handlePositiveClick={() => {
                    handleCancelWarningClose()
                    handleClose()
                }}
                dialogContentText={AlertErrorMessages.exitWithoutSaving}
                positiveButtonText={AlertButtons.yes}
                negativeButtonText={AlertButtons.no}
            />
        )
    }
    return (
        <Dialog disableEscapeKeyDown={true} fullWidth={true}
                maxWidth="lg"
                PaperProps={{
                    square: true,
                    elevation: 0,
                    sx: { border: '2px solid black', overflowY: 'hidden' }
                    }}
                open={true}
                onClose={doClose}
                aria-labelledby={windowTitleId}>
            <DialogTitle id={windowTitleId} >{existingExperimentName}</DialogTitle>
            <Box position="absolute" top={0} right={0} mt={1} mr={2}>
                {!submitInProgress && <IconButton size="large" color="secondary" onClick={handleCancelWarningOpen} aria-label="Close Dialog">
                    <CloseIcon fontSize="inherit" />
                </IconButton>}
            </Box>
            <Box name="createnew" component="form" onSubmit={handleSubmit} autoComplete="off">
                <DialogContent dividers>
                    <FlashExperimentForm experiment={existingExperimentName}
                                     projectName={projectName}
                                     responsible={responsible} collaborator={collaborator}
                                     handleSummaryChange={handleSummaryChange} benchling={benchling}
                                     noOfSamples={noOfSamples}
                                     experiment_folder={experiment_folder}
                                     peptideBarcode={peptideBarcode}
                                     invalid={!!(errorMessages && errorMessages.length > 0)}
                                     tecanExists={tcanExists}
                                     tecanFile={tecanFile} setTecanFile={setTecanFile}
                    />
                </DialogContent>
                {submitInProgress ?
                    <DialogActions>
                        <Box mr={1} display="flex" justifyContent="center">
                            <Typography variant="caption" id="savingCaption" mr={2} fontSize="1rem">
                                Saving
                            </Typography>
                            <CircularProgress aria-labelledby="savingCaption"/>
                        </Box>
                    </DialogActions>
                    :
                    <CreateNewExperimentFooter handleCancelWarningOpen={handleClose}/>
                }
            </Box>
            {renderCancelExperimentDialog()}
            {renderFormErrorsDialog()}
        </Dialog>
    )
}

export default CreateNewFlashExperimentModal