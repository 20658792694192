import {GridColDef, GridRenderEditCellParams} from '@mui/x-data-grid';
import FreeEdit from './FreeEdit';
import PositionEdit from './PositionEdit';
import TimeEdit from './TimeEdit';
import CellLineEdit from './CellLineEdit';


const renderFreeEditCell: GridColDef['renderEditCell'] = (params: GridRenderEditCellParams) => {
    return (<FreeEdit {...params} types={['Unknown', 'QC', 'Blank']}/>)
}

const renderFreeEditCellNoBlanks: GridColDef['renderEditCell'] = (params: GridRenderEditCellParams) => {
    return (<FreeEdit {...params} types={['Unknown', 'QC']}/>)
}



export const columns: GridColDef[] = [
    { field: 'Sample Type', type: 'string', width: 80, sortable: true, editable: false },
    { field: 'File Name', type: 'string', width: 300, sortable: true, editable: false },
    { field: 'Sample ID', type: 'number', width: 80, sortable: true, editable: false },
    { field: 'Path', type: 'string', width: 300, sortable: false, editable: true,
        renderEditCell: (params) => (renderFreeEditCell(params))
    },
    { field: 'Instrument Method', type: 'string', width: 300, sortable: false, editable: true,
        renderEditCell: (params) => (renderFreeEditCell(params))
    },
    { field: 'Process Method', type: 'string', width: 100, sortable: false, editable: false },
    { field: 'Calibration File', type: 'string', width: 100, sortable: false, editable: false },
    { field: 'Position', type: 'string', width: 70, sortable: true, editable: true,
        renderEditCell: (params) => (<PositionEdit {...params} />)
    },
    { field: 'Inj Vol', type: 'number', width: 70, sortable: true, editable: false },
    { field: 'Level', type: 'number', width: 70, sortable: true, editable: false },
    { field: 'Sample Wt', type: 'number', width: 80, sortable: true, editable: false },
    { field: 'Sample Vol', type: 'number', width: 80, sortable: true, editable: false },
    { field: 'ISTD Amt', type: 'number', width: 70, sortable: true, editable: false },
    { field: 'Dil Factor', type: 'number', width: 70, sortable: true, editable: false },
    { field: 'L1 Replicate', type: 'number', width: 80, sortable: true, editable: false },
    { field: 'L2 Compound', type: 'string', width: 120, sortable: true, editable: false },
    { field: 'L3 C uM', type: 'number', width: 80, sortable: true, editable: false },
    { field: 'L4 Time h', type: 'number', width: 80, sortable: true, editable: true,
        renderEditCell: (params) => (<TimeEdit {...params} />)
    },
    { field: 'L5 Cell line', type: 'string', width: 150, sortable: true, editable: true,
        renderEditCell: (params) => (<CellLineEdit {...params} />)
    },
    { field: 'Comment', type: 'string', width: 300, sortable: false, editable: false },
    { field: 'Sample Name', type: 'string', width: 100, sortable: true, editable: false },
    { field: 'Processing Method', type: 'string', width: 350, sortable: true, editable: true,
        renderEditCell: (params) => (renderFreeEditCell(params))
    },
    { field: 'Quantification Method', type: 'string', width: 150, sortable: true, editable: false },
    { field: 'Results Path', type: 'string', width: 200, sortable: true, editable: true,
        renderEditCell: (params) => (renderFreeEditCellNoBlanks(params))
    }
];
