import {useState} from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import ConfirmationDialog from '../../../../modals/ConfirmationDialog';
import LabellingCheckerModal from './LabellingCheckerModal/LabellingCheckerModal';
import PublishUi from "./PublishUi";
import ExportPdfUi from "./ExportPdfUi";
import ExperimentUploadModal from "./ExperimentUploadModal";
import {ExperimentMenuCy} from './ExperimentMenu.cy';
import menuServices from '../../../../../services/menuServices';
import {Hub} from '@aws-amplify/core';

interface MenuProps {
    publishingCallback: () => void;
    experimentType: ExperimentType;
    currentExperiment: any;
    exportPdfCallback: any;
    setExperimentMessage: (message: string) => void;
    spotfireDownloadHandler_MVITMP: () => void;
    spotfireDownloadHandler_sumPSMs: () => void;
    peptideDownloadHandler_MVITMP: () => void;
    peptideDownloadHandler_sumPSMs: () => void;
    deletionHandler: () => void;
    editExperiment: () => void;
    canDelete: boolean;
    setClone: (val: boolean) => void;
}

export default function ExperimentMenu({publishingCallback, experimentType, currentExperiment, exportPdfCallback,
                                        setExperimentMessage, 
                                        spotfireDownloadHandler_MVITMP,
                                        spotfireDownloadHandler_sumPSMs,
                                        peptideDownloadHandler_MVITMP, peptideDownloadHandler_sumPSMs, deletionHandler,
                                        editExperiment, canDelete, setClone}: MenuProps) {

    const [anchorEl, setAnchorEl] = useState(null);
    const [showConfirmRevert, setShowConfirmRevert] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showLabelCheck, setShowLabelCheck] = useState(false);
    const [showUpload, setShowUpload] = useState(false);

    const open = Boolean(anchorEl);

    const handleMoreClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMoreClose = () => {
        setAnchorEl(null);
    };
    const doLabelCheck = () => {
        setShowLabelCheck(true);
        handleMoreClose();
    }
    const hideLabelCheck = () => {
        setShowLabelCheck(false);
    }

    const doUploadExperiment = () => {
        setShowUpload(true);
        handleMoreClose();
    }
    const hideUploadExperiment = () => {
        setShowUpload(false);
    }
    const doSpotfireDownload_MVITMP = () => {
        handleMoreClose();
        spotfireDownloadHandler_MVITMP();
    }
    const doPeptideDownload_MVITMP = () => {
        handleMoreClose();
        peptideDownloadHandler_MVITMP();
    }
    const doSpotfireDownload_sumPSMs = () => {
        handleMoreClose();
        spotfireDownloadHandler_sumPSMs();
    }
    const doPeptideDownload_sumPSMs = () => {
        handleMoreClose();
        peptideDownloadHandler_sumPSMs();
    }
    const editExperimentHandler = () => {
        handleMoreClose();
        editExperiment();
    }
    const doRevertConfirmation = () => {
        setShowConfirmRevert(true);
        handleMoreClose();
    }
    const doDeleteConfirmation = () => {
        setShowConfirmDelete(true);
        handleMoreClose();
    }

    const cancelRevert = () => {
        setShowConfirmRevert(false);
    }
    const cancelDelete = () => {
        setShowConfirmDelete(false);
    }

    const setToComplete = async () => {
        if (experimentType.value === 'GENERAL') {
            const done = await menuServices.setComplete(currentExperiment.experiment, experimentType)
            if (done) {
                // console.log('dispatching on the DesignChannel')
                Hub.dispatch('DesignChannel', {event: 'new', data: {type: experimentType}})
            }
            // console.log(`set ${currentExperiment.experiment} to complete`, done)
        } else {
            throw Error('Only experiments of type GENERAL can be set to complete.')
        }
        handleMoreClose();
    }

    const doRevertToPlanned = async () => {
        const experiment = currentExperiment.experiment;
        await menuServices.revertToPlanned(experiment, experimentType).then()
    }

    const doClone = () => {
        handleMoreClose();
        setClone(true)
    }

    const doDelete = async () => {
        const experiment = currentExperiment.experiment;
        const result: any = menuServices.deleteExperiment(experiment, experimentType)
        if(result?.data?.deleteExperiment !== null) {
            deletionHandler()
        }
    }

    const currentStatus = currentExperiment.status;

    const RenderTmtOptions = () => {
        if (experimentType.value === 'GENERAL') {
            return null
        }
        return (<>
        <MenuItem key="LabelCheck" onClick={doLabelCheck}>
            Labeling Check
        </MenuItem>
        <MenuItem key="uploadResults" onClick={doUploadExperiment}
                  disabled={!experimentType.upload || currentStatus.startsWith('PUBLISH')}>
            Upload Results
        </MenuItem>
        <Typography component="li" variant="body2" color="text.secondary" fontWeight={500} mt={1} px={2}
            sx={(theme) => ({
                backgroundColor: theme.palette.background.default,
                borderTop: `1px solid ${theme.palette.divider}`
            })}
        >
            MVI TMP
        </Typography>
        <MenuItem key="MVI-TMP_SpotfireDownload" onClick={doSpotfireDownload_MVITMP}
                  disabled={currentStatus === 'PLANNED'}
                  sx={{pl: 3}}>
            Download Spotfire Run File
        </MenuItem>
        <MenuItem key="MVI-TMP_PeptideDownload" onClick={doPeptideDownload_MVITMP}
                  disabled={currentStatus === 'PLANNED'}
                  sx={{pl: 3}}>
            Download Peptide File
        </MenuItem>
        <Typography component="li" variant="body2" color="text.secondary" fontWeight={500} mt={1} px={2} 
            sx={(theme) => ({
                backgroundColor: theme.palette.background.default,
                borderTop: `1px solid ${theme.palette.divider}`
            })}
        >
            sum PSMs (legacy)
        </Typography>
        <MenuItem key="SpotfireDownload" onClick={doSpotfireDownload_sumPSMs}
                  disabled={currentStatus === 'PLANNED'}
                  sx={{pl: 3}}
                  data-cy={ExperimentMenuCy.spotfireDownload_sumPSMs}>
            Download Spotfire Run File
        </MenuItem>
        <MenuItem key="PeptideDownload" onClick={doPeptideDownload_sumPSMs}
                  disabled={currentStatus === 'PLANNED'}
                  sx={{pl: 3}}
                  data-cy={ExperimentMenuCy.peptideDownload_sumPSMs}>
            Download Peptide File
        </MenuItem>
        </>)
    }

    const RenderNotTmtOptions = () => {
        if (experimentType.value !== 'GENERAL') {
            return null
        }
        return (
            <>
            <MenuItem key="setToComplete" onClick={setToComplete}
                      disabled={currentStatus === 'COMPLETE'}>
                Set to Completed
            </MenuItem>
            </>
        )
    }

    const revertDisabled = () => {
        if (!canDelete) {
            return true
        }
        if (!currentExperiment.history.find((e: any) => e.status === "PLANNED")) {
            return true
        }
        return !(currentStatus === 'PROCESSED' || currentStatus === 'COMPLETE');

    }

    return (
        <>
            <PublishUi publishingCallback={publishingCallback}
                       experimentType={experimentType} currentExperiment={currentExperiment}/>
            <ExportPdfUi experimentType={experimentType} exportPdfCallback={exportPdfCallback}
                         setExperimentMessage={setExperimentMessage}/>
            {currentStatus === 'PLANNED' &&
                <Button size="small" variant="contained" onClick={editExperimentHandler} sx={{ ml: 1 }}>
                   Edit Experiment
                </Button>}
            <IconButton aria-label="settings" aria-controls="context-menu" aria-haspopup="true"
                        data-cy={ExperimentMenuCy.contextMenuButton} onClick={handleMoreClick} sx={{ ml: 1 }}>
                <MoreVertIcon/>
            </IconButton>
            {currentStatus && <Menu id="context-menu" anchorEl={anchorEl} keepMounted open={open}
                                    data-cy={ExperimentMenuCy.contextMenuDiv} onClose={handleMoreClose}>
                <RenderTmtOptions />
                <RenderNotTmtOptions />
                <Divider component="li" role="presentation" />
                <MenuItem key="clone" onClick={doClone} disabled={experimentType.value === 'QC'}>
                    Clone Design
                </MenuItem>
                <MenuItem key="resetToPlanned" onClick={doRevertConfirmation}
                          disabled={revertDisabled()}>
                    Revert experiment to &lsquo;PLANNED&rsquo; state
                </MenuItem>
                <MenuItem onClick={doDeleteConfirmation} disabled={!canDelete || currentStatus.startsWith('PUBLISH')}>
                    Delete Experiment
                </MenuItem>
            </Menu>}
            <ConfirmationDialog dialogTitleText={'Revert this experiment'}
                                dialogDescText={'Type name of experiment in the field below to confirm.'}
                                open={showConfirmRevert}
                                handleClose={cancelRevert}
                                submitDialog={doRevertToPlanned}
                                value={currentExperiment.experiment}/>
            <ConfirmationDialog dialogTitleText="Delete this experiment"
                                dialogDescText={'Type name of experiment in the field below to confirm.'}
                                open={showConfirmDelete}
                                handleClose={cancelDelete}
                                submitDialog={doDelete}
                                value={currentExperiment.experiment} />
            {showUpload && <ExperimentUploadModal open={showUpload} handleClose={hideUploadExperiment} currentExperiment={currentExperiment}/>}
            {showLabelCheck && <LabellingCheckerModal open={showLabelCheck} handleClose={hideLabelCheck}
                                   experimentName={currentExperiment.experiment}/>}

        </>
    )
}
